import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';

import useApi from 'hooks/useApi';
import useAddPage from 'hooks/useAddPage';

import { MultiSelect } from 'hooks/extra';

const InfractiondetailAddPage = (props) => {
		const app = useApp();
		const api=useApi();
	
		 const multiservicio = new MultiSelect();
	//form validation rules
	const validationSchema = yup.object().shape({
		
	});
	
	//form default values
	const formDefaultValues = {
		
	}
	 const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'Cajera/Agente', code: 3 },
        { name: 'General', code: 2 },
        { name: 'SuperVisor', code: 4 },
        { name: 'Administrador', code: 1 }
    ];
	/******SACAMOS LA LISTA DE UUID DE DEVICE *******/

		var devicearray=[];//CREAMOS ARREGLO DONDE GUARDAMOS LA LISTA DEL API
		const [device, setDevice] = useState(null);//CREAMOS SET Y VARIABLE PARA CONTENER LA LISTA DE LA API
		const [selectdevice, setSelectedDevice] = useState(null);//CREAMOS VARIABLES PARA EL SELECT
		 const onDeviceChange = (e) => {//CHANGE DEL SELEECT
	        setSelectedDevice(e.value);
	    }

		useEffect(() => {//MANDAMOS LLAMAR LA API DE GETDEVICE
	             multiservicio.getDevice().then(data => setDevice(data));
	       }, []);

		if(device){
		 	devicearray=multiservicio.getArraySelectDevice(device.records);
		}
	
	/******FIN *******/

	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/users`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="InfractiondetailAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik 
                        initialValues={formData} 
                        validationSchema={validationSchema} 
                        onSubmit={(values, actions) =>{
                        	values.rol=selectedCity.code;
                        	submitForm(values);
                        }
                        }>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                               	Serie 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prefix_code"  onChange={formik.handleChange}  value={formik.values.prefix_code}   label="prefix_code" type="text" placeholder="Serie" className={inputClassName(formik?.errors?.prefix_code)} />
                                                <ErrorMessage name="prefix_code" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nombre 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="firstname"  onChange={formik.handleChange}  value={formik.values.firstname}   label="firstname" type="text" placeholder="Nombre"    className={inputClassName(formik?.errors?.firstname)} />
                                                <ErrorMessage name="firstname" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Apellido 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="lastname"  onChange={formik.handleChange}  value={formik.values.lastname}   label="lastname" type="text" placeholder="Apellido"    className={inputClassName(formik?.errors?.lastname)} />
                                                <ErrorMessage name="lastname" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Usuario 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="username"  onChange={formik.handleChange}  value={formik.values.username}   label="username" type="text" placeholder="usuario"    className={inputClassName(formik?.errors?.username)} />
                                                <ErrorMessage name="username" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Clave 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="password"  onChange={formik.handleChange}  value={formik.values.password}   label="password" type="password" placeholder="Contraseña"    className={inputClassName(formik?.errors?.password)} />
                                                <ErrorMessage name="password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Clave Confirmar
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="confirm_Password"  onChange={formik.handleChange}  value={formik.values.confirm_Password}   label="confirm_Password" type="password" placeholder="Contraseña"    className={inputClassName(formik?.errors?.confirm_Password)} />
                                                <ErrorMessage name="confirm_Password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                     <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Rol 
                                            </div>
                                            <div className="col-12 md:col-9">
										            <Dropdown
										             value={selectedCity} 
										             onChange={(e) => setSelectedCity(e.value)} 
										             options={cities} 
										             optionLabel="name" 
										             placeholder="Seleciona Rol" 
										             className="w-full md:w-14rem" />
										        
                                            </div>
                                        </div>
                                    </div>

                                   
                                    
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Guardar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
InfractiondetailAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'users',
	apiPath: 'users/add',
	routeName: 'usersadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default InfractiondetailAddPage;
