import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import InfractiondetailAddPage from 'pages/infractiondetail/Add';
import useApp from 'hooks/useApp';

import useListId from 'hooks/useListId';

import useAuth from 'hooks/useAuth';

const InfractiondetailInnerdetaillistDocumentPage = (props) => {
		const app = useApp();
	const filterSchema = {
	}
	const pageController = useListId(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const {  } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;

	 const auth = useAuth();

        var ViewAuth='none';
        if(auth.userRol!=3||auth.userRol!=4){
            ViewAuth='inline-block';
        }
	

	function ActionButton(data){
		const items = [
		{
			label: "View",
			command: (event) => { app.navigate(`/RetainedDocuments/view/${data.id}`) },
			icon: "pi pi-eye"
		},
		{
			label: "Edit",
			command: (event) => { app.navigate(`/RetainedDocuments/edit/${data.id}`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}
	]
		 if(auth.userRol==3||auth.userRol==4){
            items=[];
        }
	
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function IdTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_id }</>
			);
		}
	}
	function CodeTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_code }</>
			);
		}
	}
	function DescriptionTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_description }</>
			);
		}
	}
	function DsmTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_dsm }</>
			);
		}
	}
	
	function TotalFormat(data){
		if(data){
			return (
				<>${ parseFloat(data.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Cargando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					ningún record fue encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Eliminar seleccionado"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Archivos <b>{ recordsPosition } de { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="InfractiondetailInnerdetaillistPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title="Documentos Retenido"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-fixed " style={{display:(auth.userRol==4||auth.userRol==3?'none':'block')}} >
                    <Button label="Agregar Documento" icon="pi pi-plus"  onClick={()=>app.openPageDialog(<InfractiondetailAddPage isSubPage apiPath={`/infractiondetail/add`} />, { closeBtn: true  })}  className="p-button w-full bg-primary "  />
                </div>
                <div className="col-12 md:col-3 " >
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col comp-grid" >
                    <FilterTags filterController={filterController} />
                    <div >
                        <PageBreadcrumbs />
                        <div className="page-records">
                            <DataTable 
                                lazy={true} 
                                loading={loading} 
                                selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                value={records} 
                                dataKey="id" 
                                sortField={sortBy} 
                                sortOrder={sortOrder} 
                                onSort={onSort}
                                className=" p-datatable-sm" 
                                stripedRows={true}
                                showGridlines={false} 
                                rowHover={true} 
                                responsiveLayout="stack" 
                                emptyMessage={<EmptyRecordMessage />} 
                                >
                                {/*PageComponentStart*/}
		                                <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
		                                <Column  field="document_type" header="Descripnción"  ></Column>
		                                <Column headerStyle={{width: '2rem'}} headerClass="text-center" body={ActionButton}></Column>
                                {/*PageComponentEnd*/}
                            </DataTable>
                        </div>
                        <PageFooter />
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
	);
}
InfractiondetailInnerdetaillistDocumentPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'RetainedDocuments',
	apiPath: 'RetainedDocuments/getRetainedDocumentsByInfraction',
	routeName: 'infractiondetailinnerdetaillist',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: "Eliminar el registro",
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default InfractiondetailInnerdetaillistDocumentPage;
