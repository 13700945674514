/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


import useAuth from 'hooks/useAuth';
export default function useMenus() {
    
    const auth = useAuth();


    var menu=[];

    if(auth.userRol==1){
        menu.push( {
            "to": "/home",
            "label": "Inicio",
            "icon": "pi pi-chart-pie",
            "iconcolor": "",
            "target": "",
          });

        menu.push({
            "to": "/infraction",
            "label": "Infracciones",
            "icon": "pi pi-copy",
            "iconcolor": "",
            "target": "",
          });
          menu.push({
            "to": "",
            "label": "Reporte",
            "icon": "pi pi-copy",
            "iconcolor": "",
            "target": "",
            "items": [
              {
                "to": "/kardex",
                "label": "Kardex Doc. Retenidos",
                "icon": "pi pi-users",
                "iconcolor": "",
                "target": "",
              }
            ]
          });

        menu.push({
                "to": "",
                "label": "Configuración",
                "icon": "pi pi-cog",
                "iconcolor": "",
                "target": "",
                "items": [
                  {
                    "to": "/users",
                    "label": "Usuarios",
                    "icon": "pi pi-users",
                    "iconcolor": "",
                    "target": "",
                  }
                ]
              });

        menu.push({
              "to": "",
              "label": "Catalogos",
              "icon": "pi pi-database",
              "iconcolor": "",
              "target": "",
              "items": [
                {
                  "to": "/catalogusertypes",
                  "label": "Tipos de usuario",
                  "icon": "pi pi-id-card",
                  "iconcolor": "",
                  "target": "",
                },
                {
                  "to": "/cataloginfractionitems",
                  "label": "Conceptos infracciones",
                  "icon": "pi pi-book",
                  "iconcolor": "",
                  "target": "",
                },
               {
                  "to": "/catalogdocumenttypes",
                  "label": "Documentos Retenidos",
                  "icon": "pi pi-book",
                  "iconcolor": "",
                  "target": "",
                },
                {
                  "to": "/cataloguserstatus",
                  "label": "Estatus de usuario",
                  "icon": "pi pi-user-edit",
                  "iconcolor": "",
                  "target": "",
                },
                {
                  "to": "/uma",
                  "label": "Configuración UMA",
                  "icon": "pi pi-user-edit",
                  "iconcolor": "",
                  "target": "",
                },
                {
                  "to": "/cataloginfractionstatus",
                  "label": "Estatus de Infracciones",
                  "icon": "pi pi-check-square",
                  "iconcolor": "",
                  "target": "",
                }
              ]
            });


         
    }

    if(auth.userRol==2){
      menu.push( {
            "to": "/home",
            "label": "Inicio",
            "icon": "pi pi-chart-pie",
            "iconcolor": "",
            "target": "",
          });

        menu.push({
            "to": "/infraction",
            "label": "Infracciones",
            "icon": "pi pi-copy",
            "iconcolor": "",
            "target": "",
          });

    }

    if(auth.userRol==3){
      menu.push({
            "to": "/infraction",
            "label": "Infracciones",
            "icon": "pi pi-copy",
            "iconcolor": "",
            "target": "",
          });
    }
    if(auth.userRol==4){
      menu.push({
            "to": "/infraction",
            "label": "Infracciones",
            "icon": "pi pi-copy",
            "iconcolor": "",
            "target": "",
          });
    }

    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft:menu,
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}