import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { ImageViewer } from 'components/ImageViewer';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useApi from 'hooks/useApi';
import useListPage from 'hooks/useListPage';

import { MultiSelect } from 'hooks/extra';

const UsersListPage = (props) => {
		const app = useApp();
		const api = useApi();

		 const multiservicio = new MultiSelect();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){
		var items;
	  if(data.need_revision==1){
	  		  items = [
					{
						label: "Ver",
						command: (event) => { app.navigate(`/users/view/${data.id}`) },
						icon: "pi pi-eye"
					},
					{
						label: "Editar",
						command: (event) => { app.navigate(`/users/edit/${data.id}`) },
						icon: "pi pi-pencil"
					},
					{
						label: "Vincular Equipo",
						command: (event) => { api.post("/Users/approve",{id:data.id}).then((res)=>{ 
											//app.flashMsg('--', 'll', "error");
												(res.data.status=='OK'?(app.flashMsg('', res.data.message, "success")):app.flashMsg('', res.data.message, "error"))	;
												app.navigate(0);
											}) },
						icon: "pi pi-pencil"
					},
					{
						label: "Desvincular Equipo",
						command: (event) => { 
							api.post("/Users/unlinkdevice",{id:data.id}).then((res)=>{ 
								app.navigate(0);
												//(res.data.status=='OK'?(app.flashMsg('', res.data.message, "success")):app.flashMsg('', res.data.message, "error"))	;
												
							}) 
						},
						icon: "pi pi-pencil"
					},
					{
						label: "Eliminar",
						command: (event) => { deleteItem(data.id) },
						icon: "pi pi-trash"
					}
				];
	  }else {
	  	 items = [
					{
						label: "Ver",
						command: (event) => { app.navigate(`/users/view/${data.id}`) },
						icon: "pi pi-eye"
					},
					{
						label: "Editar",
						command: (event) => { app.navigate(`/users/edit/${data.id}`) },
						icon: "pi pi-pencil"
					},
					{
						label: "Desvincular Equipo",
						command: (event) => { api.post("/Users/unlinkdevice",{id:data.id}).then((res)=>{ 
												//(res.data.status=='OK'?(app.flashMsg('', res.data.message, "success")):app.flashMsg('', res.data.message, "error"))	;
												//document.location.reload();
												app.navigate(0);
											}) },
						icon: "pi pi-pencil"
					}
				];

	  }
	 
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function IdTemplate(data){
		if(data){
			return (
				<Link to={`/users/view/${data.id}`}> { data.id }</Link>
			);
		}
	}
	function EmailTemplate(data){
		if(data){
			return (
					<a className="p-button-text" href={`mailto:${data.email}`}>{ data.email }</a>
			);
		}
	}
	function RolTemplete(data){
			var html=""; 
			if(data.rol==1){
				html=<a className="p-button-text" > Administrador </a>;
			}else if(data.rol==2){
				html=<a className="p-button-text" > General </a>;
			}else if(data.rol==3){
				html=<a className="p-button-text" > Cajero/Agente </a>;
			}
			else if(data.rol==4){
				html=<a className="p-button-text" > SuperVisor </a>;
			}
			return (html)
		
	}

	function StatusTemplete(data){
		var html=""; 
			if(data.status==1){
				html=<a className="p-button-text" > Activo </a>;
			}else{
				html=<a className="p-button-text"> Inactivo </a>;
			}
			return (html)
	}
	function StatusUUIDTemplete(data){
		var html=""; 
			if(data.need_revision==1){
				html=<a className="p-button-text" > Pendiente </a>;
			}else{
				html=<a className="p-button-text"> - - </a>;
			}
			return (html)
	}


	function ImageTemplate(data){
		if(data){
			return (
				<><ImageViewer imageSize="small" previewSize="" src={data.image} width="50px" height="50px" numDisplay={1} style={{maxWidth:'100%'}} /></>
			);
		}
	}
	function PhoneTemplate(data){
		if(data){
			return (
					<a className="p-button-text" href={`tel:${data.phone}`}>{ data.phone }</a>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Cargando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					ningún record fue encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Eliminar seleccionado"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Archivos <b>{ recordsPosition } de { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="UsersListPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title="Usuarios"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-fixed " >
                    <Link to={`/users/add`}>
                        <Button label="Agregar nuevo" icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                        </Link>
                    </div>
                    <div className="col-12 md:col-3 " >
                        <span className="p-input-icon-left w-full">
                        <i className="pi pi-search" />
                        <InputText placeholder="Buscar" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                        </span>
                    </div>
                </div>
            </div>
        </section>
        }
        <section className="page-section " >
            <div className="container-fluid">
                <div className="grid ">
                    <div className="col comp-grid" >
                        <FilterTags filterController={filterController} />
                        <div >
                            <PageBreadcrumbs />
                            <div className="page-records">
                                <DataTable 
                                    lazy={true} 
                                    loading={loading} 
                                    selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                    value={records} 
                                    dataKey="id" 
                                    sortField={sortBy} 
                                    sortOrder={sortOrder} 
                                    onSort={onSort}
                                    className=" p-datatable-sm" 
                                    stripedRows={true}
                                    showGridlines={false} 
                                    rowHover={true} 
                                    responsiveLayout="stack" 
                                    emptyMessage={<EmptyRecordMessage />} 
                                    >
                                    {/*PageComponentStart*/}
                                      <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
                                       <Column  field="prefix_code" header="Serie"   ></Column>
                                       <Column  field="firstname" header="Nombre"   ></Column>
                                       <Column  field="lastname" header="Apellido(s)"   ></Column>
                                       <Column  field="username" header="Usuario"   ></Column>
									   <Column  field="location_name" header="Ubicaciòn"   ></Column>
                                       <Column  field="phone" header="Teléfono" body={PhoneTemplate}  ></Column>
                                       <Column  field="rol" header="Permiso" body={RolTemplete}   ></Column>
                                       <Column  field="status" header="Estado" body={StatusTemplete}   ></Column>
                                       <Column  field="uuid" header="UUID"   ></Column>
                                       <Column  className="text-center" field="need_revision" header="Solicitud Pendiente de Vincular Nuevo Equipo" body={StatusUUIDTemplete}  ></Column> 
                                        <Column  field="solicited_uuid" header="UUID Solicitud"   ></Column>                           
                                       <Column headerStyle={{width: '2rem'}} headerClass="text-center" body={ActionButton}></Column>
                                    {/*PageComponentEnd*/}
                                </DataTable>
                            </div>
                            <PageFooter />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
	);
}
UsersListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'users',
	apiPath: 'users/index',
	routeName: 'userslist',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: "Eliminar el registro",
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default UsersListPage;
